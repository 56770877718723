import React, { useEffect, useState } from 'react'
import './index.scss'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import portfolioData from '../../data/portfolio.json'

const Portfolio = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const [isLoading, setIsLoading] = useState(true)
  const [animateImages, setAnimateImages] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)

    // Simulate loader completion
    const loaderTimer = setTimeout(() => {
      setIsLoading(false)
      setAnimateImages(true)
    }, 3000)

    return () => {
      clearTimeout(timer)
      clearTimeout(loaderTimer)
    }
  }, [])

  const renderPortfolio = (portfolio) => {
    return (
      <div className="images-container">
        {portfolio.map((project, idx) => {
          return (
            <div
              className={`image-box ${animateImages ? 'animate' : ''}`}
              key={idx}
              style={{ animationDelay: `${idx * 0.2}s` }}
            >
              <img
                className="project-image"
                src={project.cover}
                alt="project-image"
              />
              <div className="content">
                {project && (
                  <>
                    <p className="title">{project.title}</p>
                    <h4 className="description">{project.description}</h4>
                    {project.url && (
                      <button
                        className="btn"
                        onClick={() => {
                          const url = project.url.startsWith('http')
                            ? project.url
                            : `https://${project.url}`
                          window.open(url, '_blank', 'noopener,noreferrer')
                        }}
                      >
                        View
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="container portfolio-page">
      <h1 className="page-title">
        <AnimatedLetters
          letterClass={letterClass}
          strArray={['P', 'o', 'r', 't', 'f', 'o', 'l', 'i', 'o']}
          idx={15}
        />
      </h1>
      {isLoading ? (
        <Loader type="pacman" />
      ) : (
        <div>{renderPortfolio(portfolioData.portfolio)}</div>
      )}
    </div>
  )
}

export default Portfolio
